@use 'common/src/lib/style/settings' as vb;

/*
 * Remove template value below
 */
:host {
  display: block;
  height: 100%;
}

.theme-vinborsen {
  .find-products {
    position: relative;
    height: 46px;
    width: 350px;
    padding: 8px 16px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 64px;

    font-family: vb.$font-family-base;
    font-size: 20px;
    line-height: 26px;
    font-style: normal;
    text-rendering: geometricPrecision;

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    transition: box-shadow ease 150ms;

    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }
  }

  .find-products-icon {
    background-color: #08c470;
    height: 32px !important;
    width: 32px !important;
    position: absolute;
    border-radius: 100%;
    right: 6px;
    top: 6px;
    color: #fff;
  }
}

.rotate180 {
  display: inline-block;
  transform: rotate(180deg);
  transition: all ease-in-out 150ms;
}

.rotate-0 {
  display: inline-block;
  transform: rotate(0deg);
  transition: all ease-in-out 150ms;
}
